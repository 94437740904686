.images-download-content {
  .rdt_TableHeader {
    min-height: 0;
  }

  .status-select {
    min-width: 15rem;
  }

  .rdt_Table {
    min-height: 500px;
  }
}
