.panel {
  background-color: #fff;
  box-shadow: -5px 2px 3px -1px rgba(0,0,0,0.2);

  .close-icon {
    width: 14px;
    height: 14px;
    background: url("../images/icons/close_icon.png") no-repeat;

    &:hover {
      cursor: pointer;
    }
  }

  .panel-content {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 0;
    }
    .img-container {
      background-color: black;
      padding: 15px 0;
      img {
        max-height: 500px;
      }
    }
    .panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px 5px 15px;

      min-height: 20px;
      background-color: #4e73df;
      color: white;
      box-shadow: 0px 5px 3px -1px rgba(0,0,0,0.08);
      .title {
        font-size: 14px;
        font-weight:bold;
      }
    }
    .panel-body {
      flex: 1;
      padding: 15px 30px 15px 15px;
    }
    .panel-footer {
      background-color: #cdcdcd;
      min-height: 20px;
    }
  }
}