.reports-content {
  position: relative;

  .rdt_TableHeader {
    min-height: 0;
  }

  .rdt_Table {
    min-height: 500px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparentize($black, 0.5);
    z-index: 100;
  }
}