.svs {
  .svs-card {
    .svs-product-image {
      height: 300px;
      width: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .summary-col {
      border-bottom: 4px solid #252324;
      padding-bottom: 15px;
      .image-col {
        border-bottom: 4px solid #252324;
        margin-bottom: 15px;
      }

      .label-col {
        background-color: #252324;
        p {
          color: white;
          font-weight: bold;
        }
      }

      .value-col {
        background-color: #efefee;
      }
    }

    .sections-col {
      border-bottom: 4px solid #252324;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .section-title-col {
      background-color: #fed939;
      h4 {
        letter-spacing: 2px;
        color: #252324;
        text-transform: uppercase;
      }
    }

    .label-col,
    .value-col {
      border-bottom: 1px solid #777676;
    }

    .value-col {
      display: flex;
      align-items: center;
    }

    .label-col {
      display: flex;
      align-items: center;
      background-color: #efefee;
      p {
        color: #343434;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    p {
      margin: 5px 0;
      font-size: 14px;
    }

    .svs-features {
      .feature-category {
        background-color: #252324;
        color: white;
        font-weight:bold;
        text-align: center;
        border-left: 1px solid #7d7d7c;
        border-right: 1px solid #7d7d7c;
        border-top: 1px solid #7d7d7c;
        padding: 10px 0;
      }

      .feature-col {
        background-color: #efefee;
        border: 1px solid #7d7d7c;
        text-align: center;
        padding: 10px 0;
      }
    }
  }
}