.monitoring-stats-container {
  display: grid;
  margin-bottom: 26px;
  grid-template-columns: repeat(3, 1fr);

  > div {
    min-height: 80px;
    padding: 0em 1em 0em 1em;
    border: #e9eaf3 1px solid;

    > div {
      display: grid;
      grid-template-columns: 4fr 2fr;
    }
  }
}

.msc_c1 {
  grid-template-columns: repeat(1, 1fr);

  > div {

    > div {
      grid-template-columns: 1fr;
    }
  }
}