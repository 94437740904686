.topbar {
  .project-title {
    min-width: 180px;
    text-align: center;
    padding-left: 25px;
    color: $primary !important;
  }

  .stats-container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-around;
    height: 100%;


    .stat-object {
      height: 100%;
      color: $gray-600;

      &:hover {
        color: $primary;
      }
    }

    .stat {
      font-size: $font-size-base - 0.2rem;
    }
  }

  .spinner {
    font-size: 1.1rem;
    color: $primary;
  }
}