.search-card {
  position: relative;
  .image-info-btn {
    position: absolute;
    //z-index: 3;
    right: 10px;
    top: 10px;
    font-size: 9px;
  }

  .scrape-badge {
    position: absolute;
    //z-index: 3;
    left: 10px;
    top: 10px;
  }

  .img-provider-logo {
    margin-right: 10px;
  }

  .image-wrapper {
    position: relative;
    //z-index: 1;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    .image-overlay {
      position: absolute;
      //z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
    }

    .image-analyze-icon {
      position: absolute;
      //z-index: 3;
      top: 42%;
      left: 42%;
      transform: translate(-50%, -50%);
      font-size: 40px;
      color: #1cc88a;
    }
  }

  .image-label,
  .image-score {
    font-size: 12px;
    margin-bottom: 0px;
  }
}

.image-full {
  max-height: 400px;
  width: auto;
  max-width: 100%;
}

.selected {
  border-color: #1cc88a;
  transition: .1s all linear;
  transform: scale(0.90);
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  opacity: 0.5;
}
