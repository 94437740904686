.modal-lg {
  max-width: 70% !important;
}

// Input range filter
.input-range {
  top: 13px;
}

.form-control::-webkit-input-placeholder {
  color: #cccccc;
}

.fetching-spinner {
  font-size: 50px;
  color: $blue;
}

.sticky-container {
  position: relative;
  z-index: 2;
}

.page-link {
  z-index: 0 !important;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    @extend .form-control;
  }
}

.rdt_TableCell {
  font-size: $font-size-base !important;
}

