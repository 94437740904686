.query-modal {

  .modal-header, .modal-footer {
    padding: 0.5rem 1rem;
  }

  .modal-header {
    background-color: $primary;
    color: white;
  }

  .react-toggle {
    margin-bottom: -6px;
  }
}
