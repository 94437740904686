.summary-content {

  .subheader-filters {

    #toDateLabel {width: 70px;}
    #fromDateLabel {width: 100px;}

    label {
      margin: 0;
    }
  }

  .card {
    height: 100%;
  }

  .auto-resizer {
    div {
      height: 100% !important;
    }
  }

  .executive-summary {
    .executive-title {
      font-size: 24px;
    }

    .dupes {
      p {
        margin: 0;
        font-size: 24px;
      }

      .number-of-dupes {
        color: $primary;
        &:hover {
          cursor: pointer;
        }
      }

      span {
        //color: $primary;
        font-size: 32px;
      }

      .top-website {
        color: $primary;
        font-size: 32px;
        text-decoration: none;
      }
    }

    .highest-domain {
      p {
        font-size: 24px;
      }

      a {
        color: $primary;
        font-size: 32px;
        text-decoration: none;
      }
    }

    p {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 0;
    }

    .product-container {

      border: 1px solid #D0D0CE;
      padding: 30px 10px;

      .product-image-container {
        width: 100%;
        height: 400px;
        border: 1px solid #D0D0CE;
      }
    }

    .property-type-split {
      .pie-split-text {
        span {
          color: $primary;
        }
      }
    }

    .seller-significance {
      .high,
      .medium,
      .low {
        font-size: 24px;

        span {
          font-size: 32px;
          color: $primary
        }
      }
    }

    .significant-geographies {
      .map-legend {
        min-width: 100% !important;
      }
    }
  }

  .radial-chart-property-type-split {
    path {
      &:hover {
        cursor: pointer !important;
      }
    }
  }

  .bar-property-type-split {
    background: red;

    &:hover {
      rect {cursor: pointer}
    }
  }
}