.model-eval-content {

  position: relative;

  .model-eval-fetching-container {
    min-height: calc(100vh - 250px);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparentize($black, 0.5);
    z-index: 100;
  }
}