.login-page {
  .card-body {
    min-height: 400px;

    .row {
      min-height: 400px;
    }

  }
}
