.search-content {
  position: relative;

  .image-overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }

  .image-analyze-icon {
    position: absolute;
    z-index: 3;
    top: 42%;
    left: 42%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #1cc88a;
  }
}